import {
	type LoaderFunctionArgs,
	replace,
	type MetaFunction,
} from '@remix-run/node'
import { Form } from '@remix-run/react'
import { MoonhubLogo } from '#app/components/logo'
import { Button } from '#app/components/ui/button'
import { getUserId } from '#app/utils/auth.server'

export const meta: MetaFunction = () => [{ title: 'Moonhub' }]

export async function loader({ request }: LoaderFunctionArgs) {
	const userId = await getUserId(request)

	// if we have a user id, redirect to the dashboard
	if (userId) {
		return replace('/app')
	}

	return {}
}

export default function Index() {
	return (
		<main className="font-poppins grid h-full place-items-center">
			<div className="grid place-items-center gap-y-4 px-4 py-16 xl:grid-cols-2 xl:gap-8">
				<ul className="flex max-w-3xl flex-wrap justify-center">
					<img
						src="/img/65ee9e70ff06a331aa6dd408.png"
						alt="Moonhub"
						className="w-11/12 text-black dark:text-white"
					/>
				</ul>
				<div className="flex max-w-md flex-col items-center text-center xl:order-2 xl:items-start xl:text-left">
					<a
						href="https://www.moonhub.ai"
						className="animate-slide-top [animation-fill-mode:backwards] xl:animate-slide-left xl:[animation-delay:0.5s] xl:[animation-fill-mode:backwards]"
					>
						<MoonhubLogo className="h-14 text-black dark:text-white" />
					</a>
					<h1
						data-heading
						className="mt-8 animate-slide-top text-3xl font-medium text-foreground [animation-delay:0.3s] [animation-fill-mode:backwards] xl:mt-4 xl:animate-slide-left xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
					>
						Recruiter
					</h1>
					<Form action="/auth/auth0" method="post" className="mt-5">
						<Button variant="default" type="submit" size="lg">
							Log In
						</Button>
					</Form>
				</div>
			</div>
		</main>
	)
}
